import http from './http'

const setSession = (accessToken: string | null) => {
  if (accessToken) {
    localStorage.setItem('ca-tk', accessToken)
    http.defaults.headers.common.Authorization = `Bearer ${accessToken}`
  } else {
    localStorage.removeItem('ca-tk')
    delete http.defaults.headers.common.Authorization
  }
}

export { setSession }
