import { PATH_APP } from 'routes/paths'
import SvgIconStyle from 'components/SvgIconStyle'
import IC_Dashboard from 'assets/ic_dashboard.svg'

// ----------------------------------------------------------------------

const getIcon = (icon: any) => <SvgIconStyle src={icon} sx={{ width: 1, height: 1 }} />

const ICONS = {
  dashboard: getIcon(IC_Dashboard)
}

const navConfig = [
  {
    access: 'Student',
    subheader: 'general',
    items: [{ title: 'Courses', path: PATH_APP.courses, icon: ICONS.dashboard }]
  },
  {
    access: 'Tutor',
    subheader: 'general',
    items: [{ title: 'Dashboard', path: PATH_APP.dashboard, icon: ICONS.dashboard }]
  }
]

export default navConfig
