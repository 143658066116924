export const PATH_AUTH = {
  login: '/login',
  register: '/register',
  resetPassword: '/reset-password',
  verifyAccount: '/verify-account'
}

function path(root: string, sublink: string) {
  return `${root}${sublink}`
}

export const PATH_APP = {
  root: '/app',
  dashboard: path('/app', '/dashboard'),
  courses: path('/app', '/courses')
}
