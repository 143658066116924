import { ReactNode } from "react";
import { Alert, AlertTitle, Container } from "@mui/material";
import { useAuth } from "hooks";

// ----------------------------------------------------------------------

type Props = {
  accessibleRoles: string[]
  children: ReactNode | string
}

export default function RoleBasedGuard({ accessibleRoles, children }: Props) {
  const { role } = useAuth()

  if (!accessibleRoles.includes(role)) {
    return (
      <Container>
        <Alert severity="error">
          <AlertTitle>Permission Denied</AlertTitle>
          You do not have permission to access this page
        </Alert>
      </Container>
    )
  }

  return <>{children}</>
}
