import { forwardRef, ReactNode } from 'react'
import { m } from 'framer-motion'
import { Box, IconButton, IconButtonProps } from '@mui/material'

// ----------------------------------------------------------------------

type AnimateWrapProps = {
  children: ReactNode
  size: 'small' | 'medium' | 'large'
}

const sizeVariant = {
  small: {
    hover: { scale: 1.1 },
    tap: { scale: 0.95 }
  },
  medium: {
    hover: { scale: 1.09 },
    tap: { scale: 0.97 }
  },
  large: {
    hover: { scale: 1.08 },
    tap: { scale: 0.99 }
  }
}

function AnimateWrap({ size, children }: AnimateWrapProps) {
  return (
    <Box
      component={m.div}
      whileTap="tap"
      whileHover="hover"
      variants={sizeVariant[size]}
      sx={{
        display: 'inline-flex'
      }}
    >
      {children}
    </Box>
  )
}

// ----------------------------------------------------------------------

const IconButtonAnimate = forwardRef<HTMLButtonElement, IconButtonProps>(
  ({ children, size = 'medium', ...other }, ref) => (
    <AnimateWrap size={size}>
      <IconButton size={size} ref={ref} {...other}>
        {children}
      </IconButton>
    </AnimateWrap>
  )
)

export default IconButtonAnimate
