import { Link as RouterLink } from 'react-router-dom'
import { useTheme, Box, BoxProps } from '@mui/material'

interface Props extends BoxProps {
  disabledLink?: boolean
}

export default function Logo({ disabledLink = false, sx }: Props) {
  const theme = useTheme()
  const PRIMARY_LIGHT = theme.palette.primary.light
  const PRIMARY_MAIN = theme.palette.primary.main
  const PRIMARY_DARK = theme.palette.primary.dark

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="40.000000pt" height="40.000000pt" viewBox="0 0 40.000000 40.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g fill={PRIMARY_MAIN} fillRule="evenodd" stroke="none" strokeWidth="1" transform="translate(0.000000,40.000000) scale(0.004167,-0.004167)">
          <path 
            fill="url(#BG1)"
            d="M6595 8244 c-33 -9 -125 -34 -205 -56 -80 -22 -158 -44 -175 -48 -16 -4 -203 -56 -415 -115 -212 -59 -398 -111 -415 -115 -16 -4 -66 -18 -110 -30 -44 -12 -93 -26 -110 -30 -16 -4 -113 -31 -215 -60 -102 -28 -227 -63 -277 -76 -51 -13 -148 -40 -215 -59 -68 -19 -150 -42 -183 -51 -33 -9 -80 -22 -105 -30 -25 -7 -121 -34 -215 -59 -93 -26 -190 -53 -215 -60 -25 -7 -90 -25 -145 -40 -153 -41 -412 -113 -488 -136 -105 -31 -177 -112 -177 -202 0 -31 36 -128 93 -247 30 -63 92 -196 137 -295 46 -99 111 -240 146 -314 35 -73 64 -135 64 -136 0 -1 27 -61 61 -131 34 -71 83 -176 109 -234 26 -58 72 -154 100 -215 29 -60 78 -166 110 -235 67 -146 185 -399 259 -554 28 -59 51 -110 51 -111 0 -2 34 -74 75 -160 41 -87 75 -159 75 -160 0 -2 34 -74 75 -160 41 -87 75 -159 75 -160 0 -2 41 -90 91 -197 121 -256 195 -416 324 -693 59 -126 134 -286 166 -354 33 -67 59 -124 59 -126 0 -1 27 -61 61 -131 34 -71 83 -176 109 -234 26 -58 69 -150 95 -205 26 -55 73 -156 105 -225 121 -262 141 -300 172 -336 90 -102 260 -108 365 -11 54 49 78 94 103 190 18 69 41 157 96 365 51 193 107 405 169 647 47 181 52 202 98 375 14 52 40 149 57 215 17 66 44 167 60 225 42 158 107 411 114 443 4 15 13 27 21 27 7 0 85 -34 172 -76 87 -42 259 -125 383 -184 124 -59 293 -140 375 -180 189 -91 374 -180 490 -235 50 -23 185 -89 300 -145 364 -177 402 -192 480 -198 39 -2 88 1 111 7 54 14 119 72 152 135 24 45 27 62 26 141 0 103 3 93 -149 415 -53 113 -134 286 -180 385 -45 99 -104 225 -130 280 -26 55 -108 231 -182 390 -75 160 -153 328 -175 375 -22 47 -77 166 -123 265 -46 99 -104 225 -130 280 -26 55 -113 242 -192 415 -80 173 -162 351 -183 395 -21 44 -51 110 -68 146 -17 36 -150 324 -295 640 -257 558 -291 631 -383 828 -24 51 -44 96 -44 99 0 19 -60 87 -95 108 -54 31 -116 37 -190 18z"
          />
          <path
            fill="url(#BG2)"
            d="M2593 6798 c-10 -13 -29 -59 -203 -483 -109 -264 -118 -286 -255 -625 -48 -118 -122 -300 -165 -405 -42 -104 -116 -284 -163 -400 -47 -115 -115 -284 -151 -375 -37 -91 -71 -174 -76 -185 -5 -11 -44 -105 -86 -210 -67 -170 -143 -356 -179 -440 -7 -16 -45 -111 -85 -210 -40 -99 -94 -232 -120 -295 -183 -442 -223 -547 -227 -605 -19 -222 153 -389 356 -347 39 8 167 65 368 163 169 83 385 189 478 234 94 45 269 131 390 190 121 59 301 147 400 195 99 48 302 147 450 220 149 73 326 159 394 192 165 79 384 187 388 192 2 2 -24 60 -57 130 -33 70 -60 129 -60 130 0 4 -131 280 -134 284 -2 1 -110 -50 -242 -114 -642 -313 -1230 -600 -1604 -785 -140 -70 -256 -126 -257 -125 -1 1 27 74 63 161 36 88 78 192 93 230 16 39 49 120 74 180 25 61 88 216 140 345 53 129 118 289 145 355 27 66 89 217 137 335 86 212 131 322 221 543 25 61 89 218 141 347 53 129 113 278 134 330 21 52 39 112 39 132 0 35 -27 101 -164 393 -118 251 -160 335 -166 335 -4 0 -12 -6 -17 -12z"
          />
        </g>
      </svg>
    </Box>
  )

  if (disabledLink) {
    return <>{logo}</>
  }

  return <RouterLink to="/">{logo}</RouterLink>
}
