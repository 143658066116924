import React, { useEffect, useState } from 'react'
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  Slide,
  Stack,
  TextField,
  CircularProgress,
  Typography
} from '@mui/material'
import { TransitionProps } from '@mui/material/transitions'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import Iconify from 'components/Iconify'
import { IconButtonAnimate } from 'components/animate'
import { useDispatch, useSelector } from 'redux/store'
import { changeUserName, changeUserNameTutor } from 'redux/slices/course'
import { useAuth } from 'hooks'
import { TenopyUser } from 'types/user'

dayjs.extend(relativeTime)

// ----------------------------------------------------------------------

type Props = {
  isOpen: boolean
  studentData: TenopyUser
  handleClose: VoidFunction
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />
})

export default function ChangeUserNameDialog({ isOpen, studentData, handleClose }: Props) {
  const [updateName, setUpdateName] = useState('')
  const [updating, setUpdating] = useState(false)
  const [updateMsg, setUpdateMsg] = useState('')
  const { updatingUserName} = useSelector(state => state.course)
  const { user } = useAuth()

  const dispatch = useDispatch()

  useEffect(() => {
    if (isOpen) {
      setUpdateName(studentData.name)
    } else {
      setUpdateName('')
      setUpdateMsg('')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen])

  useEffect(() => {
    if (!updatingUserName && updating) {
      setUpdateMsg('Updated successfully!')
      setUpdating(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updatingUserName])

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUpdateName(event.target.value)
  }

  const handleSaveClick = () => {
    if (updateName !== '') {
      setUpdating(true)
      setUpdateMsg('')
      if (user?._type === 'Student') {
        dispatch(changeUserName({ name: updateName }))
      } else {
        dispatch(changeUserNameTutor({ _id: studentData._id, name: updateName }))
      }
    } else {
      setUpdateMsg('Name cannot be empty')
    }
  }

  // NOTE: change to RHF later
  return (
    <Dialog open={isOpen} TransitionComponent={Transition} maxWidth="lg" fullWidth>
      <Box sx={{ bgcolor: 'primary.main', py: 3, px: 4 }}>
        <Stack direction="row" justifyContent="space-between">
          <TextField
            label="Change Name"
            sx={{ width: '40rem', input: { color: 'white' } }}
            value={updateName}
            onChange={handleNameChange}
          ></TextField>
          <IconButtonAnimate size="small" onClick={handleClose} sx={{ p: 0.5 }}>
            <Iconify icon="eva:close-fill" width={30} height={30} sx={{ color: 'common.white' }} />
          </IconButtonAnimate>
        </Stack>
      </Box>
      <DialogContent>
        {updateMsg && (
          <Alert
            action={
              <Button color="inherit" size="small" onClick={handleClose}>
                CLOSE
              </Button>
            }
            sx={{ mb: 2 }}
          >
            {updateMsg}
          </Alert>
        )}
        {user?._type === 'Student' ? (
          <Typography>This will change your name.</Typography>
        ) : (
          <Typography>This will change the user's name.</Typography>
        )}
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" color="warning" onClick={handleClose}>
          Close
        </Button>
        <Button variant="contained" color="primary" onClick={handleSaveClick} disabled={updating}>
          Save {updating && <CircularProgress size={20} color="primary" sx={{ ml: 2 }} />}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
