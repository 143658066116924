import { styled, Box, Typography } from '@mui/material'
import MyAvatar from 'components/MyAvatar'
import { useAuth } from 'hooks'
import { useState } from 'react'
import Iconify from 'components/Iconify'
import ChangeUserNameDialog from 'pages/CourseMonitor/ChangeUserNameDialog'
import { useDispatch, useSelector } from 'redux/store'
import { TenopyUser } from 'types/user'
import { getCourse, getStudentCourseProgress } from 'redux/slices/course'

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2, 2.5),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  backgroundColor: theme.palette.grey[500_12],
  transition: theme.transitions.create('opacity', {
    duration: theme.transitions.duration.shorter
  })
}))

// ----------------------------------------------------------------------

type Props = {
  isCollapse: boolean | undefined
}

export default function UserAccount({ isCollapse }: Props) {
  const { user } = useAuth()
  const dispatch = useDispatch()
  const [selectedStudentData, setSelectedStudentData] = useState<TenopyUser>({ _id: '', name: '', email: '' })
  const [changeUserNameDialogOpen, setChangeUserNameDialogOpen] = useState(false)
  const { userName, userEmail, course } = useSelector(state => state.course)

  const handleChangeNameData = (studentData: TenopyUser) => () => {
    setSelectedStudentData(studentData)
    setChangeUserNameDialogOpen(true)
  }

  const closeChangeUserNameDialog = () => {
    setSelectedStudentData({ _id: '', name: '', email: '' })
    setChangeUserNameDialogOpen(false)
    if (course) {
      dispatch(getCourse(course._id))
    }
  }

  return (
    <RootStyle
      sx={{
        ...(isCollapse && {
          bgcolor: 'transparent'
        })
      }}
    >
      <MyAvatar />
      <Box
        sx={{
          width: '78%',
          ml: 2,
          transition: theme =>
            theme.transitions.create('width', {
              duration: theme.transitions.duration.shorter
            }),
          ...(isCollapse && {
            ml: 0,
            width: 0
          })
        }}
      >
        <Typography variant="subtitle2">
          {user?.email === userEmail ? (userName ? userName : user?.name) : user?.name}
          <Iconify
            icon="eva:edit-outline"
            width={30}
            height={20}
            sx={{
              '&:hover': {
                cursor: 'pointer'
              }
            }}
            onClick={handleChangeNameData({
              _id: user?._id,
              name: user?.name,
              email: user?.email
            })}
          />
        </Typography>

        <Typography variant="body2" noWrap sx={{ color: 'text.secondary' }}>
          {user?.email}
        </Typography>

        <ChangeUserNameDialog
          isOpen={changeUserNameDialogOpen}
          studentData={selectedStudentData}
          handleClose={closeChangeUserNameDialog}
        />
      </Box>
    </RootStyle>
  )
}
