import { useAuth } from 'hooks'
import createAvatar from 'utils/createAvatar'
import Avatar, { Props as AvatarProps } from './Avatar'

// ----------------------------------------------------------------------

export default function MyAvatar({ ...other }: AvatarProps) {
  const { user } = useAuth()

  return (
    <Avatar
      src={user?.profile_pic}
      alt={user?.name}
      color={user?.profile_pic ? 'default' : createAvatar(user?.name).color}
      {...other}
    >
      {createAvatar(user?.name).name}
    </Avatar>
  )
}
