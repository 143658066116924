import { createSlice } from '@reduxjs/toolkit'
import { findIndex } from 'lodash'
import http from 'utils/http'
import { CourseProgress, CourseState } from 'types/course'
import { dispatch } from 'redux/store'
import { orderBy } from 'lodash'
// ----------------------------------------------------------------------

const initialState: CourseState = {
  loadingCourse: false,
  error: null,
  courses: [],
  course: null,
  loadingProgress: {},
  myProgress: [], // NOTE: for student usage only
  studentOverallProgress: {}, // NOTE: for teacher usage only
  selectedStudentProgress: { courseId: '', studentId: '', progress: [] }, // NOTE: for teacher usage only
  updatingSession: false,
  updatingChapter: false,
  updatingUserName: false,
  userName: '',
  userEmail: '',
  newAddedFranchisee: null
}

const slice = createSlice({
  name: 'course',
  initialState,
  reducers: {
    loading(state) {
      state.loadingCourse = true
      state.error = null
    },
    hasError(state, action) {
      state.loadingCourse = false
      state.error = action.payload
    },
    getCoursesSuccess(state, action) {
      state.loadingCourse = false
      state.courses = action.payload
    },
    getCourseSuccess(state, action) {
      state.loadingCourse = false
      state.course = action.payload
    },
    loadingProgressFor(state, action) {},
    getMyCourseProgressSuccess(state, action) {
      let updatedMyProgress = state.myProgress
      for (let i = 0; i < action.payload.data.length; i++) {
        const existingIndex = findIndex(updatedMyProgress, o => o._id === action.payload.data[i]._id)
        if (existingIndex > -1) {
          updatedMyProgress[existingIndex] = action.payload.data[i]
        } else {
          updatedMyProgress.push(action.payload.data[i])
        }
      }
      state.myProgress = updatedMyProgress
    },
    getOverallStudentProgressSuccess(state, action) {
      let updatedStudentProgress = {} as Record<string, CourseProgress>
      for (let i = 0; i < action.payload.length; i++) {
        const progressData = action.payload[i] as CourseProgress
        updatedStudentProgress[progressData.student] = progressData
      }
      state.studentOverallProgress = updatedStudentProgress
    },
    getSelectedStudentProgressSuccess(state, action) {
      state.selectedStudentProgress = { ...action.payload }
    },
    initSessionProgressSuccess(state, action) {
      let updatedMyProgress = state.myProgress
      updatedMyProgress.push(action.payload)
      state.myProgress = updatedMyProgress
    },
    updateSessionProgressSuccess(state, action) {
      let updatedMyProgress = state.myProgress
      const existingIndex = findIndex(updatedMyProgress, o => o._id === action.payload._id)
      if (existingIndex > -1) {
        updatedMyProgress[existingIndex] = action.payload
      } else {
        updatedMyProgress.push(action.payload)
      }
      state.myProgress = updatedMyProgress
    },
    updatingSession(state) {
      state.updatingSession = true
    },
    updatingChapter(state) {
      state.updatingChapter = true
    },
    updatingUserName(state) {
      state.updatingUserName = true
    },
    newSessionSuccess(state, action) {},
    updateSessionSuccess(state, action) {
      let updatedCourse = state.course
      const existingSessionIndex = findIndex(updatedCourse?.lesson, o => o._id === action.payload._id)
      if (existingSessionIndex > -1 && updatedCourse) {
        updatedCourse.lesson[existingSessionIndex] = action.payload
      }
      state.course = updatedCourse
      state.updatingSession = false
    },
    updateChapterMetaSuccess(state, action) {
      let updatedCourse = state.course
      // const existingChapterIndex = findIndex(updatedCourse?._id, o => o === action.payload._id)
      // if (existingChapterIndex > -1 && updatedCourse) {
      updatedCourse = { ...action.payload }
      // }
      state.course = updatedCourse
      state.updatingChapter = false
    },
    updateUserNameSuccess(state, action) {
      let updatedUserName, userEmail
      updatedUserName = action.payload.name
      userEmail = action.payload.email
      state.userEmail = userEmail
      state.userName = updatedUserName
      state.updatingUserName = false
    },
    resetNewAddedFrannchisee(state) {
      state.newAddedFranchisee = null
    },
    addStudentToCourseSuccess(state, action) {
      state.newAddedFranchisee = action.payload
    },
    removeChapterFromCourseSuccess(state, action) {
      let updatedCourse = state.course
      updatedCourse = action.payload
    }
  }
})

export default slice.reducer

// ----------------------------------------------------------------------

export function getCourses() {
  return async () => {
    dispatch(slice.actions.loading)
    try {
      const response = await http.get('/v2/online-class/training')
      dispatch(slice.actions.getCoursesSuccess(response.data.data))
    } catch (error) {
      dispatch(slice.actions.hasError(error))
    }
  }
}

// ----------------------------------------------------------------------

export function getCourse(courseId: string) {
  return async () => {
    dispatch(slice.actions.loading())
    try {
      const response = await http.get(`/v2/online-class/${courseId}/data`)
      dispatch(slice.actions.getCourseSuccess(response.data.data))
    } catch (error) {
      dispatch(slice.actions.hasError(error))
    }
  }
}

// ----------------------------------------------------------------------

export function updateChapterMeta(courseId: string, reqBody: any) {
  return async () => {
    dispatch(slice.actions.updatingChapter())
    try {
      const response = await http.patch(`/v2/online-class/${courseId}/data/meta`, reqBody)
      dispatch(slice.actions.updateChapterMetaSuccess(response.data.data))
    } catch (error) {
      dispatch(slice.actions.hasError(error))
    }
  }
}
// ----------------------------------------------------------------------

export function getMyCourseProgress(courseId: string, sessionId?: string) {
  return async () => {
    dispatch(slice.actions.loadingProgressFor({ courseId, sessionId }))
    try {
      const response = await http.get(`/v2/progress/training/${courseId}`, { params: { lesson: sessionId } })

      const myProgress = orderBy(
        response.data.data,
        [
          function (myProgress: any) {
            return myProgress.lesson === ''
          },
          'created'
        ],
        ['desc', 'desc']
      )
      dispatch(slice.actions.getMyCourseProgressSuccess({ data: myProgress, courseId, sessionId }))
    } catch (error) {}
  }
}

// ----------------------------------------------------------------------

export function getStudentCourseProgress(courseId: string, studentId?: string) {
  return async () => {
    try {
      const response = await http.get(`/v2/progress/training/${courseId}/student`, { params: { student: studentId } })
      if (studentId) {
        dispatch(slice.actions.getSelectedStudentProgressSuccess({ courseId, studentId, progress: response.data.data }))
      } else {
        dispatch(slice.actions.getOverallStudentProgressSuccess(response.data.data))
      }
    } catch (error) {}
  }
}

// ----------------------------------------------------------------------

export function initSessionProgress(class_id: string, lesson_id: string) {
  return async () => {
    try {
      const response = await http.post('/v2/progress/training/init', { class_id, lesson_id })
      dispatch(slice.actions.initSessionProgressSuccess(response.data.data))
    } catch (error) {}
  }
}

// ----------------------------------------------------------------------

export function updateSessionnProgress(sessionId: string, reqBody: any) {
  return async () => {
    try {
      const response = await http.post(`/v2/progress/training/${sessionId}`, reqBody)
      dispatch(slice.actions.updateSessionProgressSuccess(response.data.data))
    } catch (error) {}
  }
}

// ----------------------------------------------------------------------

export function newSession(reqBody: any) {  
  return async () => {
    try {
      await http.post(`v2/online-lesson/training/lesson`, reqBody)
    } catch (error) {}
  }
}

// ----------------------------------------------------------------------

export function updateSession(sessionId: string, reqBody: any) {
  return async () => {
    dispatch(slice.actions.updatingSession())
    try {
      const response = await http.patch(`/v2/online-lesson/${sessionId}/training`, reqBody)
      dispatch(slice.actions.updateSessionSuccess(response.data.data))
    } catch (error) {}
  }
}

// ----------------------------------------------------------------------

export function changeUserName(reqBody: any) {
  return async () => {
    dispatch(slice.actions.updatingUserName())
    try{
       const response = await http.patch('v2/user', reqBody)
       dispatch(slice.actions.updateUserNameSuccess(response.data.data))
    } catch (error) {}
  }
}

export function changeUserNameTutor(reqBody: any) {
  return async () => {
    dispatch(slice.actions.updatingUserName())
    try{
       const response = await http.patch('v2/user/tutor', reqBody)
       dispatch(slice.actions.updateUserNameSuccess(response.data.data))
    } catch (error) {}
  }
}

// ----------------------------------------------------------------------

export function addStudentToCourse(reqBody: any) {
  return async () => {
    dispatch(slice.actions.resetNewAddedFrannchisee())
    try {
      const response = await http.post('/v2/online-class/training/add-student', reqBody)
      dispatch(slice.actions.addStudentToCourseSuccess(response.data.data))
    } catch (error) {}
  }
}

// ----------------------------------------------------------------------

export function removeStudentFromCourse(reqBody: any) {
  return async () => {
    try {
      await http.post('/v2/online-class/training/remove-student', reqBody)
    } catch (error) {}
  }
}

// ----------------------------------------------------------------------

export function removeChapterFromCourse(classId: string, reqBody: any) {
  return async () => {
    try {
      const response = await http.post(`/v2/online-class/${classId}/data/meta/chapter`, reqBody)
      dispatch(slice.actions.removeChapterFromCourseSuccess(response.data.data))
    } catch (error) {}
  }
}

// ----------------------------------------------------------------------
export function removeLessonFromCourse(classId: string, lessonId: string, reqBody: any) {
  return async () => {
    try {
      await http.post(`/v2/online-class/${classId}/data/chapter/${lessonId}`, reqBody)
    } catch (error) {}
  }
}
