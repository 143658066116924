import { createContext, ReactNode, useEffect, useReducer } from 'react'
import http from 'utils/http'
import { setSession } from 'utils/JWT'
import { ActionMap, AuthState, AuthUser, JWTContextType } from 'types/auth'

// ----------------------------------------------------------------------

enum Types {
  Init = 'INITIALIZE',
  Login = 'LOGIN',
  Logout = 'LOGOUT',
  PasswordReset = 'PASSWORD_RESET'
}

type JWTAuthPayload = {
  [Types.Init]: {
    isAuthenticated: boolean
    user: AuthUser
  }
  [Types.Login]: {
    user: AuthUser
  }
  [Types.Logout]: undefined
  [Types.PasswordReset]: object
}

export type JWTActions = ActionMap<JWTAuthPayload>[keyof ActionMap<JWTAuthPayload>]

const initialState: AuthState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
  role: 'Student'
}

const JWTReducer = (state: AuthState, action: JWTActions) => {
  switch (action.type) {
    case Types.Init:
      return {
        ...state,
        isAuthenticated: action.payload.isAuthenticated,
        isInitialized: true,
        user: action.payload.user,
        role: action.payload.user?._type ?? 'Student'
      }

    case Types.Login:
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
        role: action.payload.user?._type ?? 'Student'
      }

    case Types.Logout:
      return { ...state, isAuthenticated: false, user: null, role: 'Student' }

    case Types.PasswordReset:
      return state

    default:
      return state
  }
}

const AuthContext = createContext<JWTContextType | null>(null)

// ----------------------------------------------------------------------

type AuthProviderProps = {
  children: ReactNode
}

function AuthProvider({ children }: AuthProviderProps) {
  const [state, dispatch] = useReducer(JWTReducer, initialState)

  useEffect(() => {
    const initialize = async () => {
      const accessToken = window.localStorage.getItem('ca-tk')

      if (accessToken) {
        setSession(accessToken)
        const response = await http.get('/me')
        dispatch({
          type: Types.Init,
          payload: {
            isAuthenticated: true,
            user: response.data.data
          }
        })
      } else {
        dispatch({
          type: Types.Init,
          payload: {
            isAuthenticated: false,
            user: null
          }
        })
      }
    }

    initialize()
  }, [])

  const login = async (email: string, password: string) => {
    const response = await http.post('/auth', {
      email,
      password
    })
    const { token, data } = response.data

    setSession(token)
    dispatch({
      type: Types.Login,
      payload: {
        user: data
      }
    })
  }

  const logout = async () => {
    setSession(null)
    dispatch({ type: Types.Logout })
  }

  const passwordReset = async (email: string) => {
    const response = await http.post('/v2/auth/password-reset', {
      email
    })
    const { message } = response.data
    dispatch({ type: Types.PasswordReset, payload: { message } })
  }

  return (
    <AuthContext.Provider
      value={{
        ...state,
        login,
        logout,
        passwordReset
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export { AuthProvider, AuthContext }
