import ThemeProvider from 'theme'
import NotistackSnackbar from 'components/NotistackSnackbar'
import MotionLazyContainer from 'components/animate/MotionLazyContainer'
import ThemeColorPresets from 'components/ThemeColorPresets'
import { ProgressBarStyle } from 'components/ProgressBar'
import ScrollToTop from 'components/ScrollToTop'
import Router from 'routes'

function App() {
  return (
    <ThemeProvider>
      <ThemeColorPresets>
        <NotistackSnackbar>
        <MotionLazyContainer>
          <ProgressBarStyle />
          <ScrollToTop />
          <Router />
        </MotionLazyContainer>
        </NotistackSnackbar>
      </ThemeColorPresets>
    </ThemeProvider>
  )
}

export default App
