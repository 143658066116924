import { memo } from 'react'
import { Stack } from '@mui/material'
import { filter } from 'lodash'
import { useAuth } from 'hooks'
import { NavListRoot } from './NavList'
import { NavSectionProps } from '../type'

// ----------------------------------------------------------------------

const hideScrollbar = {
  msOverflowStyle: 'none',
  scrollbarWidth: 'none',
  overflowY: 'scroll',
  '&::-webkit-scrollbar': {
    display: 'none'
  }
} as const

function NavSectionHorizontal({ navConfig }: NavSectionProps) {
  const { role } = useAuth()
  const selectedConfig = filter(navConfig, o => o.access === role)

  return (
    <Stack direction="row" justifyContent="center" sx={{ bgcolor: 'background.neutral', borderRadius: 1, px: 0.5 }}>
      <Stack direction="row" sx={{ ...hideScrollbar, py: 1 }}>
        {selectedConfig.map(group => (
          <Stack key={group.subheader} direction="row" flexShrink={0}>
            {group.items.map(list => (
              <NavListRoot key={list.title} list={list} />
            ))}
          </Stack>
        ))}
      </Stack>
    </Stack>
  )
}

export default memo(NavSectionHorizontal)
