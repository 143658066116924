import { styled, Box, List, ListSubheader } from '@mui/material'
import { filter } from 'lodash'
import { useAuth } from 'hooks'
import { NavListRoot } from './NavList'
import { NavSectionProps } from '../type'

// ----------------------------------------------------------------------

export const ListSubheaderStyle = styled(props => <ListSubheader disableSticky disableGutters {...props} />)(
  ({ theme }) => ({
    ...theme.typography.overline,
    paddingTop: theme.spacing(3),
    paddingLeft: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    color: theme.palette.text.primary,
    transition: theme.transitions.create('opacity', {
      duration: theme.transitions.duration.shorter
    })
  })
)

// ----------------------------------------------------------------------

export default function NavSectionVertical({ navConfig, isCollapse = false, ...other }: NavSectionProps) {
  const { role } = useAuth()
  const selectedConfig = filter(navConfig, o => o.access === role)

  return (
    <Box {...other}>
      {selectedConfig.map(group => (
        <List key={group.subheader} disablePadding sx={{ px: 2 }}>
          <ListSubheaderStyle
            sx={{
              ...(isCollapse && {
                opacity: 0
              })
            }}
          >
            {group.subheader}
          </ListSubheaderStyle>
          {group.items.map(list => (
            <NavListRoot key={list.title} list={list} isCollapse={isCollapse} />
          ))}
        </List>
      ))}
    </Box>
  )
}
