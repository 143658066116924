import * as Yup from 'yup'
// form
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
// @mui
import { Stack } from '@mui/material'
import { LoadingButton } from '@mui/lab'
// hooks
import { useIsMountedRef } from 'hooks'
// components
import { FormProvider, RHFTextField } from 'components/hook-form'
// import { useDispatch } from 'redux/store'
// import { forgotPassword } from 'redux/slices/auth'
import { useAuth } from 'hooks'
// ----------------------------------------------------------------------

type FormValuesProps = {
  email: string
}

type Props = {
  onSent: VoidFunction
  onGetEmail: (value: string) => void
}

export default function ResetPasswordForm({ onSent, onGetEmail }: Props) {
  // const dispatch = useDispatch()
  const { passwordReset } = useAuth()

  const isMountedRef = useIsMountedRef()

  const ResetPasswordSchema = Yup.object().shape({
    email: Yup.string().email('Email must be a valid email address').required('Email is required')
  })

  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(ResetPasswordSchema),
    defaultValues: { email: '' }
  })

  const {
    handleSubmit,
    formState: { isSubmitting }
  } = methods

  const onSubmit = async (data: FormValuesProps) => {
    try {
      await new Promise(resolve => setTimeout(resolve, 500))
      if (isMountedRef.current) {
        onSent()
        onGetEmail(data.email)
        // dispatch(forgotPassword(data.email))
        passwordReset(data.email)
      }
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3}>
        <RHFTextField name="email" label="Email address" />

        <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isSubmitting}>
          Reset Password
        </LoadingButton>
      </Stack>
    </FormProvider>
  )
}
