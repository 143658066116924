import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { styled, Alert, Box, Card, Stack, IconButton, InputAdornment, Container, Typography, Link } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import * as Yup from 'yup'
import PageHelmet from 'components/PageHelmet'
import Logo from 'components/Logo'
import Image from 'components/Image'
import { FormProvider, RHFTextField } from 'components/hook-form'
import Iconify from 'components/Iconify'
import { useAuth, useIsMountedRef, useResponsive } from 'hooks'
import { Link as RouterLink } from 'react-router-dom'

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  }
}))

const HeaderStyle = styled('header')(({ theme }) => ({
  top: 0,
  zIndex: 9,
  lineHeight: 0,
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  position: 'absolute',
  padding: theme.spacing(3),
  justifyContent: 'space-between',
  [theme.breakpoints.up('md')]: {
    alignItems: 'flex-start',
    padding: theme.spacing(7, 5, 0, 7)
  }
}))

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 464,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(2, 0, 2, 2)
}))

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12, 0)
}))

// ----------------------------------------------------------------------

type FormValuesProps = {
  email: string
  password: string
  afterSubmit?: string
}

export default function Login() {
  const { login } = useAuth()
  const isMountedRef = useIsMountedRef()
  const mdUp = useResponsive('up', 'md')
  const [showPassword, setShowPassword] = useState(false)

  const LoginSchema = Yup.object().shape({
    email: Yup.string().email('Email must be a valid email address').required('Email is required'),
    password: Yup.string().required('Password is required')
  })

  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(LoginSchema),
    defaultValues: {
      email: '',
      password: ''
    }
  })
  const {
    reset,
    setError,
    handleSubmit,
    formState: { errors, isSubmitting }
  } = methods

  const onSubmit = async (data: FormValuesProps) => {
    try {
      await login(data.email, data.password)
    } catch (error) {
      console.error(error)
      reset()
      if (isMountedRef.current) {
        let errorMsg = 'There seems to be an issue logging in. Contact us for help.'
        if (error.message.errors[0].message === 'Invalid login credential') {
          errorMsg = 'You have entered the wrong email or password.'
        }
        setError('afterSubmit', { ...error, message: errorMsg })
      }
    }
  }

  return (
    <PageHelmet title="Login">
      <RootStyle>
        <HeaderStyle>
          <Logo />
        </HeaderStyle>

        {mdUp && (
          <SectionStyle>
            <Typography variant="h3" sx={{ px: 5, mt: 10, mb: 5 }}>
              Hi, Welcome Back
            </Typography>
            <Image
              src="https://classact-assets.s3.ap-southeast-1.amazonaws.com/web/login-illustration.png"
              alt="login"
            />
          </SectionStyle>
        )}

        <Container maxWidth="sm">
          <ContentStyle>
            <Box sx={{ mb: 5 }}>
              <Typography variant="h4" gutterBottom>
                Sign in to ClassAct
              </Typography>
              <Typography sx={{ color: 'text.secondary' }}>Enter your details below.</Typography>
            </Box>

            <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
              <Stack spacing={3} sx={{ mb: 3 }}>
                {!!errors.afterSubmit && <Alert severity="error">{errors.afterSubmit.message}</Alert>}

                <RHFTextField name="email" label="Email Address" />

                <RHFTextField
                  name="password"
                  label="Password"
                  type={showPassword ? 'text' : 'password'}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                          <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                />
              </Stack>

              <Stack direction="row" alignItems="center" justifyContent="end" sx={{ my: 2 }}>
                <Link component={RouterLink} variant="subtitle2" to="/reset-password">
                  Forgot Your Password?
                </Link>
              </Stack>

              <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isSubmitting}>
                Log in
              </LoadingButton>
            </FormProvider>
          </ContentStyle>
        </Container>
      </RootStyle>
    </PageHelmet>
  )
}
