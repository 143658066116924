import { useState } from 'react'
import { Link as RouterLink } from 'react-router-dom'
// @mui
import { styled } from '@mui/material/styles'
import { Box, Button, Container, Typography } from '@mui/material'
// layouts
// routes
import { PATH_AUTH } from '../../routes/paths'
// components
import PageHelmet from 'components/PageHelmet'
// sections
import ResetPasswordForm from './ResetPasswordForm'
// assets

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  minHeight: '100%',
  alignItems: 'center',
  justifyContent: 'center',
  padding: theme.spacing(12, 0)
}))

// ----------------------------------------------------------------------

export default function ResetPassword() {
  const [email, setEmail] = useState('')

  const [sent, setSent] = useState(false)

  return (
    <PageHelmet title="Reset Password" sx={{ height: 1 }}>
      <RootStyle>
        {/* <LogoOnlyLayout /> */}

        <Container>
          <Box sx={{ maxWidth: 480, mx: 'auto' }}>
            {!sent ? (
              <>
                <Typography variant="h3" paragraph>
                  Forgot your password?
                </Typography>

                <Typography sx={{ color: 'text.secondary', mb: 5 }}>
                  Please enter the email address associated with your account and we will email you a new password.
                </Typography>

                <ResetPasswordForm onSent={() => setSent(true)} onGetEmail={value => setEmail(value)} />

                <Button fullWidth size="large" component={RouterLink} to={PATH_AUTH.login} sx={{ mt: 1 }}>
                  Back
                </Button>
              </>
            ) : (
              <Box sx={{ textAlign: 'center' }}>
                <Typography variant="h3" gutterBottom>
                  Request sent successfully
                </Typography>

                <Typography>
                  We have sent a confirmation email to &nbsp;
                  <strong>{email}</strong>
                  <br />
                  Please check your email.
                </Typography>

                <Button size="large" variant="contained" component={RouterLink} to={PATH_AUTH.login} sx={{ mt: 5 }}>
                  Back
                </Button>
              </Box>
            )}
          </Box>
        </Container>
      </RootStyle>
    </PageHelmet>
  )
}
