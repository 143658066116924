import { ElementType, lazy, Suspense } from 'react'
import { Navigate, useLocation, useRoutes } from 'react-router-dom'
import LoadingScreen from 'components/LoadingScreen'
import { DashboardLayout } from 'layouts'
import { AuthGuard, GuestGuard, RoleBasedGuard } from 'guards'
import { PATH_AUTH } from './paths'
import ResetPassword from 'pages/auth/ResetPassword'

// ----------------------------------------------------------------------

const Loadable = (Component: ElementType) => (props: any) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation()

  const isDashboard = pathname.includes('/dashboard')

  return (
    <Suspense fallback={<LoadingScreen isDashboard={isDashboard} />}>
      <Component {...props} />
    </Suspense>
  )
}

// ----------------------------------------------------------------------

const Login = Loadable(lazy(() => import('pages/auth/Login') ))
const Dashboard = Loadable(lazy(() => import('pages/Dashboard') ))
const CourseMonitor = Loadable(lazy(() => import('pages/CourseMonitor') ))
const Courses = Loadable(lazy(() => import('pages/Courses') ))
const CourseDetail = Loadable(lazy(() => import('pages/CourseDetail')))
const TrainingSession = Loadable(lazy(() => import('pages/CourseDetail/LearningSession')))

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    {
      path: '/',
      element: (
        <GuestGuard>
          <Login />
        </GuestGuard>
      )
    },
    {
      path: PATH_AUTH.login,
      element: (
        <GuestGuard>
          <Login />
        </GuestGuard>
      )
    },
    {
     path: PATH_AUTH.resetPassword, element: <ResetPassword /> ,
  },
    {
      path: 'app',
      element: (
        <AuthGuard>
          <RoleBasedGuard accessibleRoles={['Student', 'Tutor']}>
            <DashboardLayout />
          </RoleBasedGuard>
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to='courses' replace />, index: true },
        { path: 'dashboard', element: <Dashboard /> },
        { path: 'course/:courseId/monitor', element: <CourseMonitor /> },
        { path: 'courses', element: <Courses /> },
        { path: 'course/:courseId', element: <CourseDetail /> },
        { path: 'course/:courseId/session/:sessionId', element: <TrainingSession /> }
      ]
    },
    { path: '*', element: <Navigate to="/login" replace /> }
  ])
}
