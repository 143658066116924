import axios from 'axios'
import { HOST_API } from 'config'

// ----------------------------------------------------------------------

const httpInstance = axios.create({
  baseURL: HOST_API
})

httpInstance.interceptors.response.use(
  response => response,
  error => Promise.reject((error.response && error.response.data) || 'Something went wrong')
)

export default httpInstance
