import { ReactNode } from 'react'
import { Navigate } from 'react-router-dom'
import { useAuth } from 'hooks'
import { PATH_APP } from 'routes/paths'

// ----------------------------------------------------------------------

type Props = {
  children: ReactNode
}

export default function GuestGuard({ children }: Props) {
  const { isAuthenticated, role } = useAuth()

  if (isAuthenticated) {
    let targetDestination = ''
    if (role === 'Student') {
      targetDestination = PATH_APP.courses
    } else if (role === 'Tutor') {
      targetDestination = PATH_APP.dashboard
    }

    return <Navigate to={targetDestination} />
  }

  return <>{children}</>
}
